import React from "react";
import Header from "./components/header";
import Conferences from "./components/conferences";
import PastConferences from "./components/past_conferences";
import Footer from "./components/footer";
import ConferenceEdition, {IConferenceEditionProps} from "./components/conference_edition";

import menis from "./images/speakers/brasov/menis.png";
import pellea from "./images/speakers/brasov/oana-pellea.png";


import Schedule, {IScheduleEntry} from "./components/schedule";


const App: React.FunctionComponent = () => {
    const brasovEditionProps: IConferenceEditionProps = {
        className: "container-fluid conference-edition py-5",
        containerId: "opera",
        title: "Fain & Simplu x Brașov",
        subtitle: "29 Septembrie | Teatrul Sică Alexandrescu",
        description: <div>
            <p style={{textAlign: "justify"}}><b>Fain & Simplu aduce pentru prima oară conversațiile pentru minte & suflet în „orașul de sub Tâmpa”.</b></p>
            <p style={{textAlign: "justify"}}>Într-o lume pe repede înainte, parcă omul modern uită să mai simtă. Atunci când rezultatul e mai important decât procesul, graba ruinează tot ce are legătură cu succesul: un moment de răgaz în care stai pur și simplu cu tine, o conectare reală cu cei din jurul tău, o cafea sorbită în liniște, un răsărit alături de persoana iubită sau un zâmbet sincer trimis unui trecător necunoscut.</p>
            <p style={{textAlign: "justify"}}>E timpul să te oprești o clipă, să reflectezi la ce e important pentru tine și să abordezi cu blândețe și răbdare procesul tău de transformare.</p>
            <p style={{textAlign: "justify"}}>Pentru că, nu contează cât de departe ajungi sau cât de mare e rezultatul pe care îl obții, dacă pe drum te chinui, te accidentezi în mod repetat, ”șchiopătezi” sau simți pur și simplu rămâi fără aer. Când ajungi la ”destinație”, s-ar putea să nu mai ai energie să simți cu toată ființa acel sentiment profund de bucurie.</p>
            <p style={{textAlign: "justify"}}>Ține minte: transformarea ta nu e un sprint. E un maraton.</p>
            <p style={{textAlign: "justify"}}>Te invităm în sala Teatrului Sică Alexandrescu din Brașov, la un maraton pentru minte și suflet pentru a afla <b>cum să te bucuri mai mult de procesul tău de autoactualizare. Cum să fii mai puțin critic și mult mai blând cu tine și cu cei din jur, cum să introduci mai multă răbdare și detașare</b> în procesul tău de transformare.</p>
        </div>,
        ticketsUrl: "https://tickets.fainsisimplu.ro/",
        speakers: [
            {
                name: "Dr. Menis Yousry",
                photo: menis,
                description: <div style={{textAlign: "justify"}}>
                    <p>Dr. Menis Yousry este psiholog, terapeut sistemic de cuplu și de familie, cu peste 35 de ani de experiență în acest domeniu. Este, de asemenea, speaker internațional, producător de film, artist, autor și facilitator, cunoscut pentru abilitatea sa de a crea legături emoționale profunde cu participanții la seminariile sale. Dr. Menis Yousry a lucrat timp de 15 ani în cadrul Serviciului Național de Sănătate din Marea Britanie și a fondat primul serviciu specializat pentru familie. El folosește o abordare experiențială pentru a ajuta oamenii să-și regleze emoțiile și să-și îmbunătățească percepția despre viață, promovând pacea și armonia interioară.</p>
                    <p>Când participi la un eveniment în care este invitat Dr. Menis Yousry, nu vei pleca cu o listă de „to-do-uri” sau pași de urmat. În schimb, vei descoperi resursele care se află deja în tine, gata să fie accesate ori de câte ori simți că ești pe marginea prăpastiei. „Transformarea reală nu vine din metode și tehnici, ci din conectarea la adevărul tău interior,” spune Dr. Menis Yousry într-un episod Fain & Simplu, în dialog cu Mihai Morar.</p>
                </div>,
                youtubeLink: "https://www.youtube.com/watch?v=UvOEkPzVsTc",
                youtubeName: "Dr. Menis Yousry"
            },
            {
                name: "Oana Pellea",
                photo: pellea,
                description: <div style={{textAlign: "justify"}}>
                    <p>Oana Pellea este, fără îndoială, una dintre cele mai iubite figuri din teatrul și filmul românesc. De două ori desemnată cea mai bună actriță de teatru din România și cu nenumărate premii internaționale la activ, Oana are o prezență care inspiră și atinge suflete. Dar poate cel mai frumos lucru la ea este felul în care vorbește despre sine, despre viață și despre oameni.</p>
                    <p><i>„Cel mai greu lucru în lumea asta e să te confrunți cu tine însuți și, mai mult decât atât, să ajungi să te înțelegi cu tine”</i>, spune Oana, cu o sinceritate dezarmantă. Alături de ea vom învăța cum să ne apropiem de noi înșine cu blândețe și răbdare, să ne gestionăm provocările de toate zilele și, în cele din urmă, cum să lăsăm o „dâră de frumos după ce plecăm din lumea asta”, căci, așa cum spune îndrăgita actriță, în definitiv, asta e tot ce contează cu adevărat.</p>
                </div>,
                youtubeLink: "https://www.youtube.com/watch?v=YvBgnJuzFw4",
                youtubeName: "Oana Pellea",
                sex: "f"
            }
        ]
    }
    const brasov: IScheduleEntry[] = [
        {
            time: "15:00",
            description: "Deschidere"
        },
        {
            time: "15:00 - 16:00",
            description: "Acces public și socializare"
        },
        {
            time: "16:00 - 17:45",
            description: "Prima sesiune alături de Oana Pellea"
        },
        {
            time: "17:45 - 18:45",
            description: "Pauză de socializare"
        },
        {
            time: "18:45 - 20:30",
            description: "A doua sesiune alături de Menis Yousry"
        },

        {
            time: "20:30",
            description: "Socializare si poze"
        },
    ]

    return <>
        <Header />
        <Conferences />
        <ConferenceEdition {...brasovEditionProps} />
        <Schedule title={'Agendă Fain & Simplu x Brașov'} entries={brasov} />
        <PastConferences />
        <Footer />
    </>
}

export default App;